import React, {useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import {Link} from 'react-router-dom';
//----------------- Other import -------------------
import classes from './Home.module.css';
import Burger from '../../../Assets/Images/burger.png';
import Primi from '../../../Assets/Images/primi.png';
import Pizze from '../../../Assets/Images/pizze.png';
import Dolci from '../../../Assets/Images/dolci.png';

const Categories = ({refButton, scrolltoRef}) => {
    const [categories,setCategories] = useState([{id: 1, name: 'Burger', imageUrl: Burger},
    {id: 2, name: 'Primi', imageUrl: Primi},{id: 3, name: 'Pizze', imageUrl: Pizze},{id: 4, name: 'Dolci', imageUrl: Dolci}]);


    return(<>
        <Row style={{backgroundColor: '#E22E2E', marginTop: '2rem'}}>
            <Col>
                <Row style={{marginTop: '2rem' }}>
                    <Col>
                        <span className={classes.categoryTitle}>Cosa vorresti mangiare ?</span>
                    </Col>
                </Row>
                <Row style={{marginTop: '2rem', marginBottom: '2rem'}}>
                    <Col xs={12 } md={{span:10, offset: 1}}>
                        <Row>
                            {categories.map(category => {
                                return(
                                    <Col key={category.id} xs={{span: 6}} md={6} lg={3} style={{marginTop: 10}} >
                                        <Link onClick={() => scrolltoRef()}>
                                        <Card className={classes.cardStyle} >
                                            <Row><Col><Image src={category.imageUrl} style={{width: '100%', borderTopLeftRadius: 15, borderTopRightRadius: 15}}
                                                className={classes.pictureCategoryHeight}
                                            /></Col></Row>
                                            <Row style={{marginTop: '1rem', marginBottom: '1rem'}}><Col><span className={classes.categoryName}>{category.name}</span></Col></Row>
                                        </Card>
                                        </Link>
                                    </Col>
                                )
                            })}
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    </>)
}

export default Categories;