import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
//--------------- Components ---------------------
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
import MainPage from '../../../Components/PublicPages/SupplierPage/MainPage';
import SideMenu from '../../../Components/PublicPages/SupplierPage/SideMenu';
//-------------- Other Import ------------------------
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import classes from './SupplierPage.module.css';
import { useFirebase, useFirestoreConnect, isEmpty, isLoaded } from 'react-redux-firebase';
import * as actionTypes from '../../../store/action/index';
import * as geofirestore from 'geofirestore';
import { useWindowWidth } from '@react-hook/window-size';

const SupplierPage = () => {
    const { categoryId } = useParams();
    const firebase = useFirebase();
    const dispatch = useDispatch();
    const firestore = firebase.firestore();
    const GeoFirestore = geofirestore.initializeApp(firestore);
    const geocollection = GeoFirestore.collection('suppliers');
    useFirestoreConnect({ collection: "settings", doc: "settings", storeAs: "settings" });
    useFirestoreConnect({ collection: "supplier_categories", doc: categoryId });
    const settings = useSelector(state => state.firestore.data.settings);
    const category = useSelector(state => state.firestore.ordered['supplier_categories']);
    const suppliers = useSelector(state => state.supplier.suppliers);
    const [fetchedSupplier, setFetchedSupplier] = useState([]);
    const [address, setAddress] = useState(localStorage.getItem('address') ? { ...JSON.parse(localStorage.getItem('address')) } : null);
    const [loadingSupplier, setLoadingSupplier] = useState(true);
    const [searchedText, setSearchedText] = useState('');
    const [searchedSuppliers, setSearchedSuppliers] = useState([]);
    const [fetchedTags, setFetchedTags] = useState([]);
    const [tagsChoosen, setTagsChoosen] = useState([]);
    const screenWidth = useWindowWidth();

    useEffect(() => { //This is used if you enter in the page without passing for the home page
        if (!suppliers.length && !isEmpty(settings) && isLoaded(settings)) {

            const geoQuery = geocollection.near({
                center: new firebase.firestore.GeoPoint(address.latitude, address.longitude),
                radius: settings.supplierRadius + 5,
            }).where('hidden', '==', false).where("zipCodes", 'array-contains', address.zipCode);

            geoQuery.get().then((value) => {
                let arraySupplier = [];
                value.forEach(value => {
                    arraySupplier.push(value.data());
                });

                dispatch(actionTypes.populateSuppliers(arraySupplier));
            });
        } else {
            const arraySuppliers = suppliers.filter(supplier => supplier.category === categoryId);
            setFetchedSupplier(arraySuppliers);
        }
    }, [suppliers, settings]);

    useEffect(() => {
        if (suppliers.length) {
            setLoadingSupplier(false);
        }
    }, [fetchedSupplier])


    useEffect(() => {
        if (suppliers.length && !isEmpty(category) && isLoaded(category)) {
            const availableTags = [];
            category[0].tags.forEach(tag => {
                suppliers.forEach(supplier => {
                    supplier.tags.map(supplierTag => {
                        if (supplierTag === tag.id && !availableTags.find(availableTag => availableTag.id === tag.id)) {
                            availableTags.push(tag);
                        }
                    })
                })
            })

            setFetchedTags(availableTags);
        }
    }, [category, suppliers])


    const researchHandler = (text) => {
        setSearchedText(text);
        if (text) {
            const arraySearchedRestaurant = fetchedSupplier.filter(supplier => {
                return supplier.name.toLowerCase().search(text.toLowerCase()) !== -1;
            });
            setSearchedSuppliers(arraySearchedRestaurant);
        } else {
            setSearchedSuppliers([]);
        }
    }

    const renderSupplier = () => {
        if (searchedSuppliers.length || searchedText) {
            return searchedSuppliers;
        }

        return fetchedSupplier;
    }

    const onClickTagChoosenHandler = (tagId) => {
        setSearchedText('');
        const foundTag = tagsChoosen.find(tag => tag === tagId);
        if (foundTag) {
            const newTags = [...tagsChoosen];
            const index = tagsChoosen.findIndex(tag => tag === tagId);
            newTags.splice(index, 1);
            const foundedSuppliers = [];
            suppliers.forEach(supplier => {
                supplier.tags.map(tag => {
                    newTags.map(newTag => {
                        if (tag === newTag) {
                            if (!foundedSuppliers.find(foundSupplier => foundSupplier === supplier)) {
                                foundedSuppliers.push(supplier);
                            }
                        }
                    })
                })
            })
            setTagsChoosen(newTags);
            setSearchedSuppliers(foundedSuppliers);
        } else {
            setTagsChoosen(tagsChoosen.concat(tagId));
            const foundedSuppliers = [...searchedSuppliers];
            suppliers.forEach(supplier => {
                supplier.tags.map(tag => {
                    if (tag === tagId) {
                        if (!foundedSuppliers.find(foundSupplier => foundSupplier === supplier)) {
                            foundedSuppliers.push(supplier);
                        }
                    }
                })
            })

            setSearchedSuppliers(foundedSuppliers);
        }

    }

    const onResetTagHandler = () => {
        setTagsChoosen([]);
        setSearchedSuppliers([]);
        setSearchedText('');
    }

    return (<>
        <Container fluid>
            <Row>
                <Col xs={12} md={12} lg={2} className={classes.menuBorder}>
                    {isLoaded(category) && !isEmpty(category) && category.length ?
                        <SideMenu fetchedTags={fetchedTags} onClickTagChoosenHandler={onClickTagChoosenHandler}
                            tagsChoosen={tagsChoosen} onResetTagHandler={onResetTagHandler}
                        />
                        : <LoadingButton />}
                </Col>
                <Col>
                    <Row style={{ marginTop: '2rem' }}>
                        <Col className='text-left'>
                            <input onChange={(event) => researchHandler(event.target.value)}
                                value={searchedText} placeholder='Ricerca locale'
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '2rem' }}>
                        <Col>
                            <MainPage renderSupplier={renderSupplier} fetchedSupplier={fetchedSupplier}
                                loadingSupplier={loadingSupplier} searchedSuppliers={searchedSuppliers}
                                searchedText={searchedText}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>

    </>)
}

export default SupplierPage;