
import Shift from "../models/shift";
import firebase from "firebase/app"
import CloudFunctionResult from "../models/cloud_function_result";
import IntentCreationResult from "../models/intent_creation_result";
import CustomerPaymentMethodsResult from "../models/customer_payment_methods_result";
import FindDriverResult from "../models/find_driver_result";
import CheckDiscountFunctionResult from "../models/check_discount_function_result";
import CalcCoordinatesDistanceFunctionResult from "../models/calc_coordinates_distance_function_result";


class FunctionService {
  private static _instance: FunctionService;

  constructor() {
    this.functions = firebase.functions();
  }

  public static getInstance() {
    return this._instance || (this._instance = new this());
  }

  private functions: firebase.functions.Functions;

  public async createPaymentIntent(paymentMethodId: string, amount: number, orderId: string, savePaymentMethod: boolean): Promise<IntentCreationResult> {
    let createPaymentIntent = this.functions.httpsCallable("createPaymentIntent");
    let result = await createPaymentIntent({
      paymentMethodId,
      amount,
      orderId,
      savePaymentMethod,
    });

    return result.data as IntentCreationResult;
  }

  public async createPaymentIntentFromPrevious(prevPaymentIntentId: string, amount: number, orderId: string): Promise<IntentCreationResult> {
    let createPaymentIntentFromPrevious = this.functions.httpsCallable("createPaymentIntentFromPrevious");
    let result = await createPaymentIntentFromPrevious({
      prevPaymentIntentId,
      amount,
      orderId,
    });


    return result.data as IntentCreationResult;
  }

  public async getCustomerPaymentMethods(): Promise<CustomerPaymentMethodsResult> {
    let getCustomerPaymentMethods = this.functions.httpsCallable("getCustomerPaymentMethods");
    let result = await getCustomerPaymentMethods({});

    return result.data as CustomerPaymentMethodsResult;
  }

  public async sendPhoneNumberConfirmMessage(phoneNumber: string): Promise<CloudFunctionResult> {
    let sendPhoneNumberConfirmMessage = this.functions.httpsCallable("sendPhoneNumberConfirmMessage");
    let result = await sendPhoneNumberConfirmMessage({
      phoneNumber,
    });

    return result.data as CloudFunctionResult;
  }

  public async verifyPhoneNumber(phoneNumber: string, code: string): Promise<CloudFunctionResult> {
    let verifyPhoneNumber = this.functions.httpsCallable("verifyPhoneNumber");
    let result = await verifyPhoneNumber({
      phoneNumber,
      code,
    });

    return result.data;
  }

  public async findDriverForOrder(supplierId: string, customerCoordinates: firebase.firestore.GeoPoint, shift: Shift, useCashPayment: boolean): Promise<FindDriverResult> {
    let findDriverForOrder = this.functions.httpsCallable("findDriverForOrder");
    let result = await findDriverForOrder({
      supplierId,
      customerCoordinates: {
        latitude: customerCoordinates.latitude,
        longitude: customerCoordinates.longitude,
      },
      shiftTimestamp: shift.startTime.toMillis(),
      useCashPayment,
    });

    return result.data as FindDriverResult;
  }

  public async setDriverAsAvailable(driverId: string, shiftTime: firebase.firestore.Timestamp): Promise<CloudFunctionResult> {
    let setDriverAsAvailable = this.functions.httpsCallable("setDriverAsAvailable");
    let result = await setDriverAsAvailable({
      driverId,
      shiftTimestamp: shiftTime.toMillis(),
    });

    return result.data as CloudFunctionResult;
  }

  public async checkIsDiscountUsable(discountCodeString: string, supplierId: string, areaId: string, orderTimestamp: number): Promise<CheckDiscountFunctionResult> {
    let checkIsDiscountUsable = this.functions.httpsCallable("checkIsDiscountUsable");
    let result = await checkIsDiscountUsable({
      discountCodeString,
      supplierId,
      areaId,
      orderTimestamp
    });

    return result.data as CheckDiscountFunctionResult;
  }

  public async setDiscountAsUsed(discountCodeId: string, orderId: string, supplierId: string, areaId: string, orderTimestamp: number): Promise<CloudFunctionResult> {
    let setDiscountAsUsed = this.functions.httpsCallable("setDiscountAsUsed");
    let result = await setDiscountAsUsed({
      discountCodeId,
      supplierId,
      areaId,
      orderId,
      orderTimestamp
    });

    return result.data as CloudFunctionResult;
  }

  public async setCodeAsReusable(discountCodeId: string): Promise<CloudFunctionResult> {
    let setCodeAsReusable = this.functions.httpsCallable("setCodeAsReusable");
    let result = await setCodeAsReusable({
      discountCodeId,
    });

    return result.data as CloudFunctionResult;
  }

  public async calcAddressCoordinatesAndDistance(address: string, streetNumber: string, supplierCoordinates: firebase.firestore.GeoPoint): Promise<CalcCoordinatesDistanceFunctionResult> {
    let calcCoordinatesAndDistance = this.functions.httpsCallable("calcCoordinatesAndDistance");
    let result = await calcCoordinatesAndDistance({
      address,
      streetNumber,
      supplierCoordinates: {
        latitude: supplierCoordinates.latitude,
        longitude: supplierCoordinates.longitude,
      }
    });

    return result.data as CalcCoordinatesDistanceFunctionResult;
  }
}

export default FunctionService;