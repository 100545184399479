import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
//-------------- Components ----------------
import TextBox from '../../../Components/Commons/FormComponent/TextBox';
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
import AlertGeneral from '../../../Components/Commons/AlertGeneral/AlertGeneral';
//-------------- Others --------------------
import { useForm } from 'react-hook-form';
import classes from './ResetPassword.module.css'
import { Link, useParams } from 'react-router-dom'
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";

interface FormObject {
    password: string,
    confirm_password: string,
}


const ResetPassword = () => {
    const { token } = useParams<{ token: string }>();
    const [loadingCheckToken, setLoadingCheckToken] = useState(true);
    const [successCheckToken, setSuccessCheckToken] = useState(false);
    const [errorCheckToken, setErrorCheckToken] = useState(false);
    const [loadingChangePassword, setLoadingChangePassword] = useState(false);
    const [successChangePassword, setSuccessChangePassword] = useState(false);
    const [errorChangePassword, setErrorChangePassword] = useState(false);

    const { control, register, handleSubmit, errors, watch, reset } = useForm({
    }); // initialise the hook

    const emailValidator = (value: string) => value.trim().match(/^.+@.+$/) || 'Email non valida';
    const passwordValidator = (value: string) => value.trim().match(/^[^\s]{8,20}$/) || 'Password non valida: no spazi, min 8 caratteri, massimo 20 caratteri';
    const passwordConfirmValidator = (value: string) => value === watch('password') || 'Le Password non corrispondono';

    useEffect(() => {
        setLoadingCheckToken(false)
        setSuccessCheckToken(true)
        /*clientCredentialApi.ResetPasswordCheck({reset_token: token}).then(response => {
            setSuccessCheckToken(true)
            setLoadingCheckToken(false)
        }).catch( error => {
            setErrorCheckToken(true);
            setLoadingCheckToken(false);
        });*/
    }, [])

    const onClickSubmitForm = (data: FormObject) => {
        setSuccessChangePassword(true);
        setErrorChangePassword(true);
        //setLoadingChangePassword(true)
        /*clientCredentialApi.ResetPassword({...data, reset_token: token}).then(response => {
            setLoadingChangePassword(false);
            setSuccessChangePassword(true);
        }).catch(error => {
            setErrorChangePassword(true);
            setLoadingChangePassword(false);
        });*/
    }

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Resetta password</title>
            <meta name="description" content='Resetta la tua password' />
        </Helmet>
        <Container fluid>
            {successChangePassword ?
                <AlertGeneral closeAlone={true} description='Password cambiata con successo' color='success' setTrigger={setSuccessChangePassword} />
                : null}
            <Row style={{ marginTop: '2rem' }}>
                <Col lg={{ span: 10, offset: 1 }}>
                    {loadingCheckToken ? <LoadingButton /> :
                        successCheckToken ?
                            <Card className={classes.card} >
                                <Row><Col><h1 className={classes.titleStyle}>Scegli una nuova password</h1></Col></Row>
                                <Row><Col><span className={classes.textStyle}>La nuova password deve essere di almeno 8 caratteri, deve contenere una lettera maiuscola e può contenere numeri e simboli.</span></Col></Row>
                                <Form onSubmit={handleSubmit(onClickSubmitForm)} >
                                    <Row><Col>
                                        <Row style={{ marginTop: '1rem' }}>
                                            <Col xs={12} lg={6} className='colMarginForm'>
                                                <TextBox ref={register({ validate: passwordValidator })} placeHolder={'Inserisci nuova password'} type={'password'} name={'password'}
                                                    error={errors.password ? errors.password.message : ''} offset={0} span={12} labelName={'Password'}
                                                    labelStyle={{ textTransform: 'capitalize', fontWeight: 'bolder' }}
                                                />
                                            </Col>
                                            <Col xs={12} lg={6} className='colMarginForm'>
                                                <TextBox ref={register({ validate: passwordConfirmValidator })} placeHolder={'Ripeti nuova password'} type={'password'} name={'confirm_password'}
                                                    error={errors.confirm_password ? errors.confirm_password.message : ''} offset={0} span={12} labelName={'Conferma Password'}
                                                    labelStyle={{ textTransform: 'capitalize', fontWeight: 'bolder' }}
                                                />
                                            </Col>
                                        </Row>
                                        {errorChangePassword ?
                                            <Row style={{ marginTop: 20 }}>
                                                <Col>
                                                    <span className='errorStyle'>
                                                        Errore nel resettare la password
                                                </span>
                                                </Col>
                                            </Row>
                                            : null}
                                        <Row style={{ marginTop: '2.5rem' }}><Col>
                                            {loadingChangePassword ? <LoadingButton /> :
                                                successChangePassword ? null :
                                                    <Button type='submit' className={'buttonGeneralStyle'} style={{ width: 200 }}>
                                                        Cambia Password
                                                </Button>
                                            }
                                            {successChangePassword ?
                                                <Link to='/'>
                                                    <Button className={'buttonGeneralStyle'} style={{ width: 200 }}>
                                                        Accedi
                                                    </Button>
                                                </Link>
                                                : null}
                                        </Col></Row>
                                    </Col></Row>
                                </Form>
                            </Card>
                            : errorCheckToken ?
                                <span style={{ fontSize: 30, fontWeight: 'bold' }} className='text-danger'>Token Error</span>
                                : null
                    }
                </Col>
            </Row>
        </Container>
    </>)
}


export default ResetPassword