
import Product from "./product";

type Cart = {
  products?: CartProduct[],
  supplier_id?: string,
}

export type CartProduct = {
  id: string,
  quantity: number,
  uniqId: number,
  preferences: CartProductPreference[],
}

export type CartProductPreference = {
  id: string,
  name: string,
  type?: string,
  price: number,
}

export function getCartAmount(cart: Cart, products: Product[]): number {
  if (cart.products === undefined)
    return 0;
  return cart.products.reduce(function (prev, cp) {
    let product = products.find((p) => cp.id === p.id);
    if (product === undefined)
      return prev;
    return prev + getCartProductAmount(cp, product);
  }, 0);
}

export function getCartProductAmount(cartProduct: CartProduct, product: Product): number {
  let price = cartProduct.preferences.reduce((prev, p) => prev + (p.type === "text" ? 0 : p.price), product.price);

  price = price > 0 ? price : 0;
  return price * cartProduct.quantity;
}


export default Cart;