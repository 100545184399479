import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import classes from './ProfilePage.module.css';
import Image from 'react-bootstrap/Image';
import { useHistory } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import OrderStates from '../../../models/order_states';
import moment from 'moment';
import ButtonWithConfirmation from '../../ButtonWithConfirmation';
import FirestoreService from '../../../services/firestore_service';
import { orderStateToString } from '../../../models/order';
import { toastr } from 'react-redux-toastr'

const YourCartDetailModal = ({ onHideCartDetail, order, checkDate, renderPrice, getTime }) => {
    const history = useHistory();

    const onClickEditOrder = () => {
        history.push('/modifica-ordine/' + order.reference.id);
    }

    const renderItemPrice = (productPrice, preferences) => {
        let price = productPrice;
        if (preferences && preferences.length) {
            preferences.forEach(preference => {
                if (preference.price) {
                    price += preference.price;
                }
            })
        }

        return price;
    }

    console.log(order)

    if (!order)
        return <></>

    return (<>
        <Modal show={true} onHide={() => onHideCartDetail()}>
            <Modal.Header closeButton>
                <Row>
                    <Col>
                        <h3 className={classes.modalTitile}>Dettaglio ordine</h3>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body >
                {
                    order.state === OrderStates.NEW && order.postponeRequested === true &&
                    <Row>
                        <Col className='text-center'>
                            <Card style={{ padding: 20 }}>
                                <Row>
                                    <Col>
                                        <span style={{ fontSize: 18, color: 'blue' }}>
                                            {order.isPreponed ? "Richiesta di anticipo" : "Richiesta di posticipo"}
                                        </span>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Col>
                                        <span>Nuova data di consegna:</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <span style={{ fontWeight: 'bolder' }}>{moment(order.preferredDeliveryTimestamp.toMillis()).format("DD-MM-YYYY HH:mm")}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} lg={{ span: 8, offset: 2 }}>
                                        <Row>
                                            <Col xs={12} lg={6}>
                                                <ButtonWithConfirmation className='buttonGeneralStyle'
                                                    modalTitle="Rifiuto della richiesta"
                                                    modalDescription={`Sicuro di voler rifiutare la richiesta di ${order.isPreponed ? "anticipo" : "posticipo"}?\n\nIl tuo ordine verrà automaticamente rifiutato.`}
                                                    onConfirm={async () => {
                                                        try {
                                                            const firestore = FirestoreService.getInstance();
                                                            const result = await firestore.updateOrderState(order.reference.id, OrderStates.REFUSED);
                                                            if (!result) {
                                                                toastr.error("Errore imprevisto!", "Impossibile rifiutare la richiesta in quanto l'ordine ha cambiato stato.");
                                                            }
                                                            else {
                                                                toastr.warning("Richiesta rifiutata con successo!");
                                                            }
                                                        }
                                                        catch (err) {
                                                            console.log(err);
                                                            toastr.error("Errore inaspettato!", "Errore inaspettato durante il rifiuto della richiesta.");
                                                        }
                                                    }}>
                                                    Rifiuta
                                                </ButtonWithConfirmation>
                                            </Col>
                                            <Col xs={12} lg={6}>
                                                <ButtonWithConfirmation className='buttonGeneralStyle'
                                                    modalTitle="Accettazione della richiesta"
                                                    modalDescription={`Sicuro di voler accettare la richiesta di ${order.isPreponed ? "anticipo" : "posticipo"}?`}
                                                    onConfirm={async () => {
                                                        try {
                                                            const firestore = FirestoreService.getInstance();
                                                            const result = await firestore.updateOrderState(order.reference.id, OrderStates.ACCEPTED);
                                                            if (!result) {
                                                                toastr.error("Errore imprevisto!", "Impossibile accettare la richiesta in quanto l'ordine ha cambiato stato.");
                                                            }
                                                            else {
                                                                toastr.success("Richiesta accettata con successo!");
                                                            }
                                                        }
                                                        catch (err) {
                                                            console.log(err);
                                                            toastr.error("Errore inaspettato!", "Errore inaspettato durante l'accettazione della richiesta.");
                                                        }
                                                    }}>
                                                    Accetta
                                                </ButtonWithConfirmation>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                }

                <Row>
                    <Col>
                        <span className={classes.textType}>Codice: </span> <span>#{order.reference.id}</span>
                    </Col>
                    <Col>
                        <span className={classes.textType}>Data: </span> <span>{checkDate(order.creationTimestamp.seconds)}</span>
                    </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                    <Col>
                        <span className={classes.textType}>Stato: </span><span>{orderStateToString(order)}</span>
                    </Col>
                    <Col>
                        <span className={classes.textType}>Prodotti: </span><span>{order.cartAmount} €</span>
                    </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                    <Col><span className={classes.textType}>Spedizione: </span> <span>{order.deliveryAmount} €</span></Col>
                    <Col>{order.discountAmount ? <><span className={classes.textType}>Sconto: </span><span>{order.discountAmount} €</span> </> : null}</Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                    <Col><span className={classes.textType}>Totale: </span><span>{renderPrice(order.cartAmount, order.deliveryAmount, order.discountAmount)} €</span></Col>
                    <Col><span className={classes.textType}>Pagamento: </span><span>{order.useChashPayment ? 'In contanti' : 'Carta'}</span></Col>
                </Row>

                <Row style={{ marginTop: 10, backgroundColor: 'black', padding: 5 }}>
                    <Col className='text-center'><span className={classes.textSection}>
                        Data di consegna richiesta</span>
                    </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                    <Col>
                        <p><span className={classes.textType}>Data: </span> <span>{checkDate(order.preferredDeliveryTimestamp.seconds)}</span></p>
                        <p><span className={classes.textType}>Ora: </span><span>{getTime(order.preferredDeliveryTimestamp.seconds)}</span></p>
                    </Col>
                </Row>
                <Row style={{ marginTop: 10, backgroundColor: 'black', padding: 5 }}>
                    <Col className='text-center'>
                        <span className={classes.textSection}>Indirizzo di spedizione</span>
                    </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                    <Col>
                        <p><span className={classes.textType}>{order.customerName}</span></p>
                        <p><span className={classes.textType}>Indirizzo: </span><span>{order.customerAddress}</span></p>
                        <p><span className={classes.textType}>Citofono/numero civico: </span><span>{order.customerStreetNumber}</span></p>
                    </Col>
                </Row>
                <Row style={{ marginTop: 10, backgroundColor: 'black', padding: 5 }}>
                    <Col className='text-center'>
                        <span className={classes.textSection}>Fornitore</span>
                    </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                    <Col xs={4}><Image style={{ width: '100%' }} src={order.supplierImageUrl} /></Col>
                    <Col className='my-auto'>
                        <p><span className={classes.textType}>{order.supplierName}</span></p>
                        <p><span className={classes.textType}>{order.supplierPhoneNumber}</span></p>
                    </Col>
                </Row>
                <Row style={{ marginTop: 10, backgroundColor: 'black', padding: 5 }}>
                    <Col className='text-center'>
                        <span className={classes.textSection}>Fattorino</span>
                    </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                    {
                        order.useOwnDrivers === true ?
                            <span className="ml-2">Consegna gestita dal fornitore</span> :
                            <>
                                <Col xs={4}><Image style={{ width: '100%' }} src={order.driverImageUrl} /></Col>
                                <Col className='my-auto'>
                                    <p><span className={classes.textType}>{order.driverName}</span></p>
                                    <p><span className={classes.textType}>{order.driverPhoneNumber}</span></p>
                                </Col>
                            </>
                    }
                </Row>
                <Row style={{ marginTop: 10, backgroundColor: 'black', padding: 5 }}>
                    <Col className='text-center'>
                        <span className={classes.textSection}>Prodotti Ordinati</span>
                    </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                    <Col>
                        {order.products.map(product => {
                            return (
                                <Row>
                                    <Col>
                                        <p>{product.name}</p>
                                        <p> <span>{product.notes} </span>
                                            {product.preferences && product.preferences.length ?
                                                product.preferences.map(preference => {
                                                    return (<span>{preference.name} </span>)
                                                })
                                                : null}
                                        </p>
                                    </Col>
                                    <Col className='text-right'>
                                        <p>{renderItemPrice(product.price, product.preferences)} €</p>
                                        <p>x{product.quantity ? product.quantity : '1'}</p>
                                    </Col>
                                </Row>
                            )
                        })}
                    </Col>
                </Row>
                {
                    order.state === "NEW" && order.postponeRequested !== true &&
                    <Row style={{ marginTop: 20 }}>
                        <Col className='text-center'>
                            <ButtonWithConfirmation className='buttonGeneralStyle' style={{ width: 150 }}
                                modalTitle="Annulla l'ordine" modalDescription="Sicuro di voler annullare l'ordine?"
                                onConfirm={async () => {
                                    try {
                                        const firestore = FirestoreService.getInstance();
                                        const result = await firestore.updateOrderState(order.reference.id, OrderStates.CANCELLED);
                                        if (!result) {
                                            toastr.error("Errore imprevisto!", "Impossibile procedere all'annullamento in quanto l'ordine ha cambiato stato.");
                                        }
                                        else {
                                            toastr.success("Ordine annullato con successo!");
                                        }
                                    }
                                    catch (err) {
                                        console.log(err);
                                        toastr.error("Errore inaspettato!", "Errore inaspettato durante l'annullamento dell'ordine.");
                                    }
                                }}>
                                Annulla Ordine
                            </ButtonWithConfirmation>
                        </Col>
                        {
                            /*
                        <Col className='text-right'>
                            <Button className='buttonGeneralStyle' style={{ width: 150 }}
                                onClick={() => onClickEditOrder()}
                            >
                                Modifica ordine
                            </Button>
                        </Col>
                            */
                        }
                    </Row>
                }

            </Modal.Body>
        </Modal>
    </>)
}


export default YourCartDetailModal;