import React, {useEffect} from 'react'
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classes from './AlertGeneral.module.css';

interface Props {
    color: string,
    closeAlone?: Boolean,
    description: string,
    setTrigger: Function,
}
const AlertGeneral:React.FC<Props> = ({color,closeAlone,description,setTrigger}) => {
 
    useEffect( () => {
        if(closeAlone){
            const time = setTimeout(() => setTrigger(false), 3000)
            return() => {
                clearTimeout(time)
            }
        }
    },[])

    return(
        <>
        <Alert className={classes.alertStyle} variant={color} onClose={() => setTrigger(false)} dismissible>
            <Row>
                <Col className='text-center'>
                    <span className={classes.description}>{description}</span>
                </Col>
            </Row>
        </Alert>
        </>
    );
}


export default AlertGeneral;