import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
//--------------- Components ---------------
import ChooseAddress from '../../Commons/ChoosingAddress/ChoosingAddress';
import AlertStatic from '../../Commons/AlertStatic/AlertStatic';
//---------------- Other import ----------------
import * as geofirestore from 'geofirestore';
import { useFirebase, isLoaded, isEmpty } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import * as actionTypes from '../../../store/action/index';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useHistory } from 'react-router-dom';
import classes from './Home.module.css';
import { getDistance } from 'geolib';

const AddressBar = ({ addressRef }) => {
    const [address, setAddress] = useState(localStorage.getItem('address') ? { ...JSON.parse(localStorage.getItem('address')) } : { address: '', latitude: '', longitude: '', zipCode: '' });
    const supplierMissing = useSelector(state => state.supplier.supplierMissing);

    useFirestoreConnect({ collection: "settings", doc: "settings", storeAs: "settings" });
    const settings = useSelector(state => state.firestore.data.settings);

    const firebase = useFirebase();
    const dispatch = useDispatch();
    const history = useHistory();
    const firestore = firebase.firestore();
    const GeoFirestore = geofirestore.initializeApp(firestore);
    const geocollection = GeoFirestore.collection('suppliers');

    useEffect(() => {
        localStorage.setItem('address', JSON.stringify(address));
        if (supplierMissing) {
            dispatch(actionTypes.cleanSupplierMissing());
        }
    }, [address]);

    const searchForSupplierHandler = async () => {
        const geoQuery = geocollection.near({
            center: new firebase.firestore.GeoPoint(address.latitude, address.longitude),
            radius: settings.supplierRadius,
        }).where('hidden', '==', false).where("zipCodes", 'array-contains', address.zipCode);

        const querySnap = await geoQuery.get();
        const areasSnap = await firestore.collection("areas").get();

        const areas = areasSnap.docs.map((s) => s.data());

        let suppliers = querySnap.docs.map((s) => s.data());
        let distances = [];
        for (let s of suppliers) {
            // eslint-disable-next-line no-loop-func
            const customerCoordinates = {
                latitude: address.latitude,
                longitude: address.longitude,
            }
            const supplierCoordinates = {
                latitude: s.geohashPoint.geopoint.latitude,
                longitude: s.geohashPoint.geopoint.longitude,
            }
            distances.push(getDistance(customerCoordinates, supplierCoordinates));
        }

        let idsToRemove = [];
        for (let i = 0; i < suppliers.length; i++) {
            // eslint-disable-next-line no-loop-func
            const area = areas.find((a) => a.id === suppliers[i].areaId);
            suppliers[i].area = area;
            if (distances[i] > area.supplierRadius * 1000) idsToRemove.add(suppliers[i].id);
        }

        suppliers = suppliers.filter((s) => !idsToRemove.includes(s.id));

        dispatch(actionTypes.populateSuppliers(suppliers));
        if (suppliers.length) {
            history.push('/categorie');
        }
    }

    return (
        <>
            <Row style={{ marginTop: 20 }}>
                <Col className='text-right'>
                    <div className={classes.addressBarDivStyle}>
                        <ChooseAddress
                            setAddress={setAddress}
                            address={address}
                            addressRef={addressRef}
                        />
                        <Button className={`buttonGeneralStyle ${classes.taportyMarginTop}`} disabled={!address.address} style={{ marginLeft: 10, width: 150 }}
                            onClick={() => searchForSupplierHandler()}
                        >
                            Ordina Ora
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={{ span: 6, offset: 3 }}>
                    {supplierMissing ? <AlertStatic description='Non ci sono ristoranti nella zona' color='danger' /> : null}
                </Col>
            </Row>
        </>
    )
}

export default AddressBar;