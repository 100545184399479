import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
//-------------- Adding components ------------------
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
//-------------- Other Import ------------------------
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import * as geofirestore from 'geofirestore';
import { useFirebase } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import * as actionTypes from '../../../store/action/index';
import classes from './CategoriesPage.module.css';
import { Link } from 'react-router-dom';

const CategoriesPage = () => {
    useFirestoreConnect({ collection: "supplier_categories", orderBy: [["index", "asc"]] });
    const firebase = useFirebase();
    const dispatch = useDispatch();
    const firestore = firebase.firestore();
    const GeoFirestore = geofirestore.initializeApp(firestore);
    const geocollection = GeoFirestore.collection('suppliers');
    useFirestoreConnect({ collection: "settings", doc: "settings", storeAs: "settings" });
    const settings = useSelector(state => state.firestore.data.settings);
    //---------------- States managment ---------------
    const [address, setAddress] = useState(localStorage.getItem('address') ? { ...JSON.parse(localStorage.getItem('address')) } : null);
    const suppliers = useSelector(state => state.supplier.suppliers);
    const categories = useSelector(state => state.firestore.ordered['supplier_categories']);
    const [fetchedCategory, setFetchedCategory] = useState([]);
    const [loadingCategory, setLoadingCategory] = useState(true);
    const [searchedText, setSearchedText] = useState('');
    const [searchedCategory, setSearchedCategory] = useState([]);


    useEffect(() => {
        if (categories && suppliers.length) {
            const arrayFetchedCategory = [];
            categories.forEach(category => {
                const foundSupplier = suppliers.find(supplier => supplier.category === category.id);
                if (foundSupplier) {
                    arrayFetchedCategory.push(category);
                }
            });
            setFetchedCategory(arrayFetchedCategory);
            setLoadingCategory(false);
        }
    }, [categories, suppliers]);

    useEffect(() => { //This is used if you enter in the page without passing for the home page
        if (!suppliers.length && !isEmpty(settings) && isLoaded(settings)) {
            const geoQuery = geocollection.near({
                center: new firebase.firestore.GeoPoint(address.latitude, address.longitude),
                radius: settings.supplierRadius + 5,
            }).where('hidden', '==', false).where("zipCodes", 'array-contains', address.zipCode);

            geoQuery.get().then((value) => {
                let arraySupplier = [];
                value.forEach(value => {
                    arraySupplier.push(value.data());
                });

                dispatch(actionTypes.populateSuppliers(arraySupplier));
            });

            setLoadingCategory(false);
        }
    }, [suppliers, settings]);

    const researchHandler = (text) => {
        setSearchedText(text);
        if (text) {
            const arraySearchedCategory = fetchedCategory.filter(category => {
                return category.name.toLowerCase().search(text.toLowerCase()) !== -1;
            });
            setSearchedCategory(arraySearchedCategory);
        } else {
            setSearchedCategory([]);
        }
    }

    const renderCategory = () => {
        if (searchedText) {
            return searchedCategory;
        }

        return fetchedCategory;
    }

    return (<>
        <Container fluid >
            <Row style={{ marginTop: '2rem' }}>
                <Col>
                    <Row>
                        <Col className='text-left'>
                            <input onChange={(event) => researchHandler(event.target.value)}
                                value={searchedText} placeholder='Ricerca categoria'
                            />
                        </Col>
                    </Row>
                    {loadingCategory ? <LoadingButton /> :
                        fetchedCategory.length ?
                            <Row style={{ marginTop: '2rem' }}>
                                {renderCategory().map(category => {
                                    return (
                                        <Col key={category.id} xs={6} md={6} lg={3} style={{ marginTop: 10 }}>
                                            <Link to={`/suppliers/${category.id}`}>
                                                <Card className={classes.cardStyle}>
                                                    <Row><Col><Image src={category.imageUrl} style={{ width: '100%', borderTopLeftRadius: 15, borderTopRightRadius: 15 }} className={classes.heightCard} /></Col></Row>
                                                    <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}><Col>
                                                        <span className={classes.textName}>{category.name}</span>
                                                    </Col></Row>
                                                </Card>
                                            </Link>
                                        </Col>
                                    )
                                })}
                            </Row>
                            :
                            <>
                                <span className='text-danger'>Non ci sono categorie</span>
                            </>
                    }
                    {!searchedCategory.length && searchedText ?
                        <Row style={{ marginTop: '2rem' }}>
                            <Col>
                                <span className='text-danger'>La tua ricerca non ha prodotto risultati</span>
                            </Col>
                        </Row>
                        : null}
                </Col>
            </Row>
        </Container>
    </>)
}


export default CategoriesPage;