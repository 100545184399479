import { FunctionComponent } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import okLoader from '../../../Assets/Animation/ok-loader.json';
import Lottie from 'react-lottie'
import { useWindowWidth } from '@react-hook/window-size';

const OrderConfirmStep: FunctionComponent = () => {
    const screenWidth = useWindowWidth();
    const defualtOptions = {
        loop:true,
        autoplay: true,
        animationData: okLoader,
        renderSettings: {
            preserveAspectRation: "xMidYmid slice"
        }
    }

    return (<>
        <Row>
            <Col>
                <span>Ordine inviato con successo</span>
            </Col>
        </Row>
        <Row style={{marginTop: '2rem'}}>
            <Col>
                <Lottie options={defualtOptions} width={screenWidth > 1025 ? '30%' :'80%'} />
            </Col>
        </Row>
    </>)
}


export default OrderConfirmStep;