import { FunctionComponent, useState } from "react";
import Button from 'react-bootstrap/Button';
import ConfirmModal from "./Commons/ConfirmModal/ConfirmModal";

type ButtonWithConfirmationProps = {
  className?: string;
  disabled?: boolean;
  modalTitle: string;
  modalDescription?: string;
  onConfirm: () => any;
  style?: React.CSSProperties,
}

const ButtonWithConfirmation: FunctionComponent<ButtonWithConfirmationProps> = ({
  modalTitle,
  onConfirm,
  className,
  disabled,
  modalDescription,
  style,
  children
}) => {
  const [show, setShow] = useState(false);
  return <>
    <Button className={className ?? "buttonGeneralStyle"} style={style} onClick={() => setShow(true)} disabled={disabled}>{children}</Button>
    <ConfirmModal description={modalDescription} title={modalTitle} handleCancel={() => setShow(false)} show={show}
      handleConfirm={() => {
        onConfirm();
        setShow(false);
      }} />
  </>
}

export default ButtonWithConfirmation;