import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Terms = () => {
    return(<>
        <Container fluid >
            <Row style={{marginTop: '2rem'}}>
                <Col className='text-left'><span style={{color: '#E22E2E', fontWeight: 'bold', fontSize: 24}}>Termini e condizioni Taporty</span></Col>
            </Row>
            <Row style={{marginTop: '1rem'}}>
                <Col className='text-left'><span style={{color: '#E22E2E', fontWeight: 'bold', fontSize: 20}}>1 VARIAZIONE DI TERMINI E CONDIZIONI</span></Col>
            </Row>
            <Row style={{marginTop: '1rem'}}>
                <Col className='text-left'><span>TAPORTY si riserva il diritto di aggiornare, integrare e modificare, in tutto o in parte, in qualsiasi momento i presenti Termini e Condizioni e ciascuno dei documenti da queste richiamato, ivi compresa l'Informativa sulla Privacy (di seguito, "Policy Privacy"). Per tale ragione, ti invitiamo a prendere periodicamente visione delle medesime. </span></Col>
            </Row>
            <Row style={{marginTop: '1rem'}}>
                <Col className='text-left'><span>In particolare, le modifiche, gli aggiornamenti e le integrazioni alle Condizioni Generali e/o ai servizi offerti saranno efficaci dal momento della pubblicazione sul Sito e si intenderanno, da quel momento, conosciuti dagli utenti in quanto portati alla loro conoscenza mediante modalità idonee, nonché accettati dagli utenti che accedano e utilizzino il Sito successivamente alla pubblicazione stessa.</span></Col>
            </Row>
            <Row style={{marginTop: '1rem'}}>
                <Col className='text-left'><span style={{color: '#E22E2E', fontWeight: 'bold', fontSize: 20}}>2 UTILIZZO DEI SERVIZI TAPORTY</span></Col>
            </Row>
            <Row style={{marginTop: '1rem'}}>
                <Col className='text-left'><span>In sede di Registrazione al Sito ed utilizzando i Servizi, l'Utente garantisce di essere maggiorenne e di avere la piena capacità di agire. L'Utente dichiara, inoltre, che i dati personali forniti sono veritieri, corretti, aggiornati, riferiti alla propria persona (sia nel caso di Utente-persona giuridica che di Utente-persona fisica) ovvero, nel caso di Utente che operi per conto terzi, che si tratti di dati inseriti con il consenso del terzo, assumendo ogni responsabilità in ordine alla correttezza ed alla veridicità delle informazioni fornite.</span></Col>
            </Row>
            <Row style={{marginTop: '1rem'}}>
                <Col className='text-left'><span style={{color: '#E22E2E', fontWeight: 'bold', fontSize: 20}}>3 RESPONSABILITA’ </span></Col>
            </Row>
            <Row style={{marginTop: '1rem'}}>
                <Col className='text-left'><span>L'attività svolta da TAPORTY consiste esclusivamente nell'offrire agli Utenti una piattaforma dove gli Utenti possono trovare il fornitore considerato da loro più adatto per la soddisfazione delle proprie esigenze. </span></Col>
            </Row>
            <Row style={{marginTop: '1rem'}}>
                <Col className='text-left'><span>L’app TAPORTY mette a disposizione solamente alcune informazioni a supporto della valutazione da parte dell’Utente ed a tal fine rende disponibili i dati e le informazioni forniti dai fornitori oltre ai feedback rilasciati dai Clienti sotto la loro esclusiva responsabilità. Tali informazioni non sono verificate dalla Società e resta, in ogni caso, inteso che la Società non assume alcuna responsabilità in ordine alle stesse. </span></Col>
            </Row>
            <Row style={{marginTop: '1rem'}}>
                <Col className='text-left'><span>Ne consegue che la scelta del fornitore ritenuto più opportuno, sono fatte dal Cliente in completa autonomia con conseguente assunzione di ogni responsabilità da parte di quest’ultimo e ciò anche con riferimento alla verifica che i servizi resi dal fornitore siano stati eseguiti secondo quanto specificato nella area dedicata al fornitore. </span></Col>
            </Row>
            <Row style={{marginTop: '1rem'}}>
                <Col className='text-left'><span>TAPORTY non si assume alcuna responsabilità e, di conseguenza, non potrà essere ritenuta responsabile in merito ad alcun profilo delle offerte e prestazioni effettuate tramite la Piattaforma, e, quindi, a mero titolo esemplificativo e non tassativo, a: </span></Col>
            </Row>
            <Row style={{marginTop: '1rem'}}>
                <Col className='text-left'>
                    <ul>
                        <li><span>la veridicità e accuratezza dei Contenuti caricati dagli Utenti sul Sito</span></li>
                        <li><span>la capacità e professionalità dei fornitori nel prestare i servizi indicati o a svolgerli secondo le modalità concordate con il Cliente nonché in merito al soddisfacimento appieno delle esigenze e delle aspettative del Cliente con riferimento ai risultati ottenuti dalla fruizione del servizio da parte del medesimo;</span></li>
                        <li><span>la mancata e/o inesatta prestazione del servizio reso al Cliente dal fornitore, anche in ordine ad eventuali difetti o difformità sopravvenuti in esito alla conclusione dello stesso;</span></li>
                        <li><span>il mancato o ritardato o incompleto pagamento da parte del Cliente dei corrispettivi dovuti in relazione ai servizi prestati dal Professionista;</span></li>
                        <li><span>la veridicità delle informazioni e dei dati, anche personali, inseriti dagli Utenti, l’eventuale violazione di diritti di terzi, compresa la violazione di brevetti, marchi, segreti, diritti di autore o altri diritti di proprietà industriale e/o intellettuale, ovvero il mancato rispetto di qualunque normativa di legge applicabile alle operazioni realizzate attraverso il Sito e qualunque danno patrimoniale e non patrimoniale sofferto dagli Utenti oppure da terzi in connessione con qualunque attività legata all’uso del Sito. </span></li>
                    </ul>
                </Col>
            </Row>
            <Row style={{marginTop: '1rem'}}>
                <Col className='text-left'><span>TAPORTY non potrà inoltre essere ritenuta in alcun modo responsabile per: </span></Col>
            </Row>
            <Row style={{marginTop: '1rem'}}>
                <Col className='text-left'>
                    <ul>
                        <li><span>qualsiasi perdita di avviamento o reputazione commerciale, di profitto, di dati, sia che si configuri come diretta così come indiretta o consequenziale;</span></li>
                        <li><span>qualsiasi perdita o danno, diretti, indiretti o consequenziali, che gli stessi Utenti potrebbero subire come risultato di:  1. qualsiasi affidabilità riposta sulla completezza, correttezza o esistenza di qualsivoglia Contenuto pubblicato dagli Utenti sul Sito; 2. qualsiasi modifica che la Società possa apportare al Sito o ai Servizi, o per qualsiasi interruzione temporanea o permanente nella fornitura degli stessi; 3. la cancellazione, corruzione, o mancata memorizzazione, di qualsiasi Contenuto, nonché comunicazione di dati, mantenuti o trasmessi, da o tramite l'uso dei Servizi da parte dell'Utente; 4. il mancato invio di precise informazioni da parte dell'Utente;</span></li>
                    </ul>
                </Col>
            </Row>
            <Row style={{marginTop: '1rem'}}>
                <Col className='text-left'><span style={{color: '#E22E2E', fontWeight: 'bold', fontSize: 20}}>4 MANLEVA</span></Col>
            </Row>
            <Row style={{marginTop: '1rem'}}>
                <Col className='text-left'><span>Accettando le presenti Condizioni Generali, l'Utente - sia Cliente sia Professionista - si impegna a manlevare e tenere indenne TAPORTY, nonché gli esponenti della predetta e tutte le società del Gruppo ed i loro esponenti, da qualsiasi pretesa e/o rivendicazione e/o eccezione e/o richiesta risarcitoria, comprese le spese legali, causate e/o derivanti dall’offerta e/o esecuzione dei servizi tutti promossi e resi tramite la Piattaforma nonché dalla violazione dei presenti Termini e Condizioni o dalla violazione di qualsiasi legge e regolamento applicabile o diritto di terzi.</span></Col>
            </Row>
            <Row style={{marginTop: '1rem'}}>
                <Col className='text-left'><span>LEGGENDA</span></Col>
            </Row>
            <Row style={{marginTop: '1rem'}}>
                <Col className='text-left'>
                    <ul>
                        <il><span>TAPORTY: piattaforma che offre l’app che consente ai clienti di poter acquistare tramite essa prodotti e consegnati a domicilio;</span></il>
                        <li><span>fornitori: coloro che sono presenti sulla vetrina di TAPORTY che vendono i propri beni ai clienti tramite app.</span></li>
                    </ul> 
                </Col>
            </Row>
        </Container>
    
    </>)
}


export default Terms;