
import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import { reducer as toastrReducer } from 'react-redux-toastr'
import supplierReducer from './supplierReducer';

const rootReducer = combineReducers({
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  supplier: supplierReducer,
  toastr: toastrReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer