import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
//-------------- Components ----------------\
import TextBox from '../../../Components/Commons/FormComponent/TextBox';
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
import ModalSimple from '../../../Components/Commons/ModalSimple/ModalSimple';
import AlertGeneral from '../../../Components/Commons/AlertGeneral/AlertGeneral';
//-------------- Others --------------------
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom'
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { useFirebase } from 'react-redux-firebase'
//------------- Style -----------------------
import classes from './Registration.module.css'
import FirestoreService from '../../../services/firestore_service';

interface FormObject {
    email: string;
    password: string;
    password_confirmation: string;
    name: string;
    surname: string;
}

const Registration = () => {
    const [loadingApi, setLoadingApi] = useState<true | false>(false);
    const [errorApiAccount, setErrorApiAccount] = useState<true | false>(false);
    const [errorApi, setErrorApi] = useState<true | false>(false);
    const [successApi, setSuccessApi] = useState<true | false>(false);
    const [terms, setTerms] = useState<true | false>(false);
    const [termsError, setTermsError] = useState<true | false>(false);
    const firebase = useFirebase();

    const { control, register, handleSubmit, errors, watch, reset } = useForm({
    }); // initialise the hook

    const emailValidator = (value: string) => value.trim().match(/^.+@.+$/) || 'Email non valida';
    const passwordValidator = (value: string) => value.trim().match(/^[^\s]{8,20}$/) || 'Password non valida: no spazi, min 8 caratteri, massimo 20 caratteri';
    const passwordConfirmValidator = (value: string) => value === watch('password') || 'Le Password non corrispondono';

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const onClickContactHandler = async (data: FormObject) => {
        if (!terms) {
            setTermsError(true)
        } else {
            setLoadingApi(true);

            try {
                let authResult = await firebase.auth().createUserWithEmailAndPassword(data.email, data.password);
                await FirestoreService.getInstance().createUser(authResult.user!.uid, data.name, data.email);

                setLoadingApi(false);
                setSuccessApi(true);
                setErrorApiAccount(false);
                setErrorApi(false);
            }
            catch (error) {
                setLoadingApi(false);
                if (error.response && error.response.status === '400') {
                    setErrorApiAccount(true);
                } else {
                    setErrorApi(true);
                }
            }
        }
    }

    const onChangeSelect = () => {
        setTerms(!terms);
        setTermsError(false);
    }

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Registrazione</title>
            <meta name="description" content='Registrati a Topaty' />
        </Helmet>
        { successApi ? <AlertGeneral setTrigger={setSuccessApi} closeAlone={false} color='success' description={"Ti abbiamo mandato un' email per confermare la tua registrazione"} /> : null}
        <Container fluid >
            <Row style={{ marginTop: '2rem' }}>
                <Col xl={{ span: 10, offset: 1 }}>
                    <Card className={classes.card} >
                        <Row><Col><h1 className={classes.titleStyle}>Registrati</h1></Col></Row>
                        <Form onSubmit={handleSubmit(onClickContactHandler)} >
                            <Row><Col>
                                <Row className='rowMarginForm'>
                                    <Col xs={12} lg={6}>
                                        <TextBox ref={register({ validate: emailValidator })} placeHolder={'Inserisci la tua email'} type={'email'} name={'email'}
                                            error={errors.email ? errors.email.message : ''}
                                            labelStyle={{ textTransform: 'capitalize', fontWeight: 'bolder' }}
                                            offset={0} span={12} labelName={'Email'}
                                        />
                                    </Col>
                                    <Col xs={12} lg={6} className='colMarginForm'>
                                        <TextBox ref={register({ required: 'true' })} placeHolder={'Inserisci il tuo nome'} type={'input'} name={'nominative'}
                                            error={errors.nominative ? 'Campo richiesto' : ''} offset={0} span={12} labelName={'Nome'}
                                            labelStyle={{ textTransform: 'capitalize', fontWeight: 'bolder' }}
                                        />
                                    </Col>
                                </Row>
                                <Row className='rowMarginForm'>
                                    <Col xs={12} lg={6} className='colMarginForm'>
                                        <TextBox ref={register({ validate: passwordValidator })} placeHolder={'Inserisci la tua password'} type={'password'} name={'password'}
                                            error={errors.password ? errors.password.message : ''} offset={0} span={12} labelName={'Password'}
                                            labelStyle={{ textTransform: 'capitalize', fontWeight: 'bolder' }}
                                        />
                                    </Col>
                                    <Col xs={12} lg={6} className='colMarginForm'>
                                        <TextBox ref={register({ validate: passwordConfirmValidator })} placeHolder={'Conferma la tua password'} type={'password'} name={'confirm_password'}
                                            error={errors.confirm_password ? errors.confirm_password.message : ''} offset={0} span={12} labelName={'Conferma Password'}
                                            labelStyle={{ textTransform: 'capitalize', fontWeight: 'bolder' }}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '2rem' }}>
                                    <Col>
                                        <Form.Check
                                            type={'checkbox'}
                                            label={<> <span className={classes.textStyle}>Accetto i </span>
                                                <Link to='/termini-e-condizioni' target='_blank' ><span className={classes.linkStyle}>Termini e Condizioni</span></Link>
                                                <span className={classes.textStyle}> e </span>
                                                <a href='https://www.iubenda.com/privacy-policy/60234852' target='_blank' ><span className={classes.linkStyle}>Privacy Policy</span></a> </>}
                                            checked={terms}
                                            onChange={() => onChangeSelect()}
                                        />
                                    </Col>
                                </Row>
                                {errorApi || errorApiAccount || termsError ?
                                    <Row style={{ marginTop: 20 }}>
                                        <Col>
                                            <span className='errorStyle'>
                                                {errorApi ? "C'è stato un errore! Riprova nuovamente!" : null}
                                                {errorApiAccount ? "Email gia in uso" : null}
                                                {termsError ? "Per favore leggi e accetta termini condizioni e privacy policy" : null}
                                            </span>
                                        </Col>
                                    </Row>
                                    : null}
                                <Row style={{ marginTop: '2.5rem', paddingLeft: 20 }}><Col>
                                    {loadingApi ?
                                        <LoadingButton />
                                        :
                                        <Button type='submit' className={'buttonGeneralStyle'} style={{ width: 200 }}>
                                            Registrati
                                        </Button>
                                    }
                                </Col></Row>
                            </Col></Row>
                        </Form>
                        <Row style={{ marginTop: '1rem' }}>
                            <Col>
                                <span className={classes.textStyle}>Hai gia un account? </span>
                                <Link to='/accedi'>
                                    <span className={classes.linkStyle}>Accedi</span>
                                </Link>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>)
}


export default Registration