import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import classes from './SupplierDetailPage.module.css';

const ModalCartFull = ({emptyCartHandler}) => {

    return(<>
        <Modal show={true}  >
            <Modal.Header >
                <Row>
                    <Col>
                        <h3 className={classes.modalTitile}>Carrello Pieno</h3>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body >
                <Row>
                    <Col>
                        <span>Il tuo carrello e' pieno con prodotti di un altro ristorante, svuota il carrello per poter continuare.
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button onClick={() => emptyCartHandler()}>Svuota carrello</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    
    </>)
}


export default ModalCartFull;