import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
//-------------- Other import ------------------
import { Link } from 'react-router-dom'
import classes from './ProfilePage.module.css';

const SideMenu = ({ onChangeChoosenTabHandler, choosenTab, auth, logoutHandler }) => {
    return (<>
        <Row style={{ marginTop: '1rem' }}>
            <Col>
                <Link onClick={() => onChangeChoosenTabHandler('profile')}>
                    <span className={`${choosenTab === 'profile' ? classes.choosenText : classes.notChoosenText}`}>
                        Il tuo profilo
                    </span>
                </Link>
            </Col>
        </Row>
        <Row style={{ marginTop: '1rem' }}>
            <Col>
                <Link onClick={() => onChangeChoosenTabHandler('reset-password')}>
                    <span className={`${choosenTab === 'reset-password' ? classes.choosenText : classes.notChoosenText}`}>
                        Cambia password
                    </span>
                </Link>
            </Col>
        </Row>
        <Row style={{ marginTop: '1rem' }}>
            <Col>
                <Link onClick={() => onChangeChoosenTabHandler('carrelli')}>
                    <span className={`${choosenTab === 'carrelli' ? classes.choosenText : classes.notChoosenText}`}>
                        I tuoi ordini
                    </span>
                </Link>
            </Col>
        </Row>
        {/* }
        <Row style={{marginTop: '1rem'}}>
            <Col>
                <Link onClick={() => onChangeChoosenTabHandler('points')}>
                    <span className={`${choosenTab === 'points' ? classes.choosenText : classes.notChoosenText}`}>
                        I tuoi punti
                    </span>
                </Link>
            </Col>
        </Row> */}
        {auth ?
            <Row style={{ marginTop: '1rem' }}>
                <Col>
                    <Button onClick={logoutHandler} className='buttonGeneralStyle' style={{ width: 100 }} >Logout</Button>
                </Col>
            </Row>
            : null}
    </>)
}


export default SideMenu;