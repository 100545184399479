import { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
//-----------Components ---------------
import SectionTitle from './SectionTitle';
import LoadingButton from '../../Commons/LoadingButton/LoadingButton';
import YourCartDetailModal from './YourCartDetailModal';
//----------- Other import ---------------
import classes from './ProfilePage.module.css';
import { useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useWindowWidth } from '@react-hook/window-size';
import { FaHamburger } from 'react-icons/fa';
import { orderStateToString } from '../../../models/order';

const YourCarts = () => {
    const { auth } = useSelector((state) => ({ auth: state.firebase.auth, profile: state.firebase.profile }));
    useFirestoreConnect({ collection: "orders", where: ["customerId", "==", auth.uid], orderBy: ["creationTimestamp", "desc"] });
    const orders = useSelector(state => state.firestore.ordered['orders']);
    const [choosenItem, setChoosenItem] = useState(null);
    useFirestoreConnect(
        choosenItem === null ? [] : [
            { collection: "orders", doc: choosenItem, storeAs: "chosenOrder" }
        ]
    );
    const order = useSelector(state => state.firestore.data.chosenOrder);
    const screenWidth = useWindowWidth();

    const checkDate = (creationDate) => {
        const date = new Date(creationDate * 1000);
        return `${date.getDate()}/${date.getUTCMonth() + 1}/${date.getFullYear()}`
    }

    const getTime = (creationDate) => {
        const date = new Date(creationDate * 1000);
        return `${date.getHours()}:${date.getMinutes()}`
    }

    const renderPrice = (cartAmount, deliveryAmount, discountAmount) => {
        let price = 0;
        if (cartAmount) {
            price += cartAmount;
        }
        if (deliveryAmount) {
            price += deliveryAmount;
        }

        if (discountAmount) {
            price -= discountAmount;
        }

        return price.toFixed(2);
    }

    const onClickItemHandler = (orderId) => {
        setChoosenItem(orderId);
    }

    const onHideCartDetail = () => {
        setChoosenItem(null);
    }

    const renderColorStatus = ({ state, postponeRequested }) => {

        if (state === 'PICKED_UP') {
            return '4px solid #17A2B8'
        }

        if (state === 'REFUSED') {
            return '4px solid #DC3545'
        }

        if (state === 'NEW') {
            if (postponeRequested === true)
                return '4px solid #6C757D'
            return '4px solid #FFC107'
        }

        if (state === 'ACCEPTED') {
            return '4px solid #17A2B8'
        }
        if (state === 'DELIVERED') {
            return '4px solid #28A745'
        }
        if (state === 'CANCELLED') {
            return '4px solid #DC3545'
        }
        if (state === 'READY') {
            return '4px solid #17A2B8'
        }

        return 'none'
    }

    return (<>
        {choosenItem && !isEmpty(order) && isLoaded(order) ?
            <YourCartDetailModal onHideCartDetail={onHideCartDetail} order={order}
                checkDate={checkDate} renderPrice={renderPrice} getTime={getTime}
            />
            : null}
        <SectionTitle title={'I tuoi ordini'} />
        <Row>
            <Col >
                {screenWidth > 1025 ?
                    <Row style={{ backgroundColor: '#E22E2E', padding: 10, margin: 20 }} className={classes.hideMobile}>
                        <Col><span className={classes.fontTableHeader}>Data</span></Col>
                        <Col xs={3}><span className={classes.fontTableHeader}>Id</span></Col>
                        <Col xs={3}><span className={classes.fontTableHeader}>Stato</span></Col>
                        <Col><span className={classes.fontTableHeader}>N* prodotti</span></Col>
                        <Col><span className={classes.fontTableHeader}>Prezzo</span></Col>
                    </Row>
                    : null}
                {isLoaded(orders)
                    ?
                    <Row style={{ padding: screenWidth > 1025 ? 10 : 0, margin: screenWidth > 1025 ? 20 : 0 }}>
                        <Col>
                            {orders.map((order, index) => {
                                return (<>
                                    {screenWidth > 1025 ?
                                        <Row style={{ marginTop: 5 }} key={index}>
                                            <Col style={{ borderLeft: renderColorStatus(order) }}><span>{checkDate(order.preferredDeliveryTimestamp.seconds)}</span></Col>
                                            <Col xs={3}>
                                                <span onClick={() => onClickItemHandler(order.reference.id)} style={{ cursor: 'pointer' }}>
                                                    #{order.reference.id}
                                                </span>
                                            </Col>
                                            <Col xs={3}><span>{orderStateToString(order)}</span></Col>
                                            <Col><span>{order.productCount}</span></Col>
                                            <Col><span>{renderPrice(order.cartAmount, order.deliveryAmount, order.discountAmount)} €</span></Col>
                                        </Row>
                                        :
                                        <Row style={{ marginTop: 5 }} key={index}>
                                            <Col className='text-left'>
                                                <Card style={{ padding: 20 }}>
                                                    <Row>
                                                        <Col style={{ borderLeft: renderColorStatus(order) }}>
                                                            <Row><Col><span>{checkDate(order.preferredDeliveryTimestamp.seconds)}</span></Col></Row>
                                                            <Row><Col><span style={{ fontWeight: 'bold' }}>Ordine: #{order.reference.id}</span></Col></Row>
                                                            <Row><Col><span>{orderStateToString(order)}</span></Col></Row>
                                                            <Row><Col>
                                                                <span><FaHamburger /> {order.productCount}  </span>
                                                                <span>  € {renderPrice(order.cartAmount, order.deliveryAmount, order.discountAmount)} </span>
                                                            </Col></Row>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        </Row>
                                    }
                                </>)
                            })}
                            {orders.length === 0 &&
                                <div>Nessun ordine ancora effettuato.</div>
                            }
                        </Col>
                    </Row>
                    : <LoadingButton />}
            </Col>
        </Row>
    </>)
}


export default YourCarts;