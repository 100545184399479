import React from 'react';
//-------------- Bootstrap ------------------
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
//-------- Other import -------------------
import { Link } from 'react-router-dom';
import ReactGA from "react-ga";
import Image404 from '../../Assets/Images/404.png';
import { Helmet } from "react-helmet";
import { FaHome } from 'react-icons/fa';
//--------------Style--------------------------------
import classes from './PageError.module.css';

const trackPage = (page: string) => {
    ReactGA.set({ page });
    ReactGA.pageview(page);
};

const Page404 = () => {
    return (
        <>
            {trackPage('404')}
            <Helmet>
                <meta charSet="utf-8" />
                <title>404</title>
                <meta name="description" content='Non Trovato' />
                <meta name="robots" content="noindex" />
            </Helmet>
            <Container>
                <Row style={{ marginTop: '2rem' }}>
                    <Col className='text-left'>
                        <FaHome size={22} />
                        <Link to='/' className='colorRed' style={{ paddingLeft: 10 }}>
                            <span>Home</span>
                        </Link>
                    </Col>
                </Row>
                <Row className={classes.RowTitle}>
                    <Col xs={{ span: 12 }}>
                        <h1 className='colorRed'>404 la pagina non e' stata trovata</h1>
                    </Col>
                </Row>
                <Row>
                    <Col lg={{ span: 6, offset: 3 }}>
                        <span >La pagina che stai navigando non e' stata trovata, puoi tornare alla pagina home seguendo il link in alto a sinistra, o cambiare url di destinazione, grazie</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Image
                            className={classes.Image}
                            src={Image404}
                            alt='404-error-image-frutta'
                        />
                    </Col>
                </Row>
            </Container>
        </>

    )
}

export default Page404
