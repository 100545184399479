import React, { useState, useEffect, useRef } from 'react';
import Container from 'react-bootstrap/Container';
//------------ Add components ----------------------
import Features from '../../../Components/PublicPages/Home/Features';
import WorkWithUs from '../../../Components/PublicPages/Home/WorkWithUs';
import Categories from '../../../Components/PublicPages/Home/Categories';
import Cover from '../../../Components/PublicPages/Home/Cover';
import Download from '../../../Components/PublicPages/Home/Download';
//------------ Other import ------------------------
import { Helmet } from "react-helmet";
import { useFirebase } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import ReactPixel from 'react-facebook-pixel';


const HomePage = () => {
    //const firebase = useFirebase();
    //useFirestoreConnect({collection: "supplier_categories", orderBy: [["index", "asc"]] });
    //const test = useSelector(state => state.firestore.data['supplier_categories']);
    //const categories = useSelector(state => state.firestore.ordered['supplier_categories']);
    const refButton = useRef(null);
    const addressRef= useRef(null)

    const scrolltoRef = () => {
        refButton.current.scrollIntoView({ behavior: 'smooth' });
        //addressRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    useEffect(() => {
      ReactPixel.pageView(); // For tracking page view
    },[])

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Home</title>
            <meta name="description" content='Benvenuto su Topaty' />
        </Helmet>
        <Container fluid className='containerStyle'>
            <Cover refButton={refButton} addressRef={addressRef}/>
            <Features />
            <Categories refButton={refButton} scrolltoRef={scrolltoRef}/>
            <Download />
            <WorkWithUs />
        </Container>
    </>)
}

export default HomePage;