import React, {useEffect, useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
//------------------ Components --------------------
import SideMenu from '../../../Components/PrivatePages/ProfilePage/SideMenu';
import ProfileForm from '../../../Components/PrivatePages/ProfilePage/ProfileForm';
import ChangePasswordForm from '../../../Components/PrivatePages/ProfilePage/ChangePasswordForm';
import YourCarts from '../../../Components/PrivatePages/ProfilePage/YourCarts';
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
import AlertGeneral from '../../../Components/Commons/AlertGeneral/AlertGeneral';
//------------------ Other import ---------------------
import { useFirebase, isLoaded, isEmpty,  } from 'react-redux-firebase';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import classes from './ProfilePage.module.css';

const ProfilePage = () => {
    const firebaseApi = useFirebase();
    const history = useHistory();
    const { auth, profile } = useSelector((state) => ({ auth: state.firebase.auth, profile: state.firebase.profile }));
    const [choosenTab, setChoosenTab] = useState('profile'); //profile,reset-password, points
    const [logoutError, setLogoutError] = useState(false);

    useEffect(() => {
        if(isLoaded(profile) && isEmpty(profile) && isLoaded(auth) && isEmpty(auth)){
            history.push('/');
        }
    },[profile, auth]);

    const onChangeChoosenTabHandler = (tab) => {
        setChoosenTab(tab);
    }

    const renderProfileComponent = () => {
        if(choosenTab === 'profile'){
            return <ProfileForm />
        }
        if(choosenTab === 'reset-password'){
            return <ChangePasswordForm />
        }
        if(choosenTab === 'carrelli'){
            return <YourCarts />
        }
    }

    const logoutHandler = () => {
        firebaseApi.auth().signOut().then(() => {
            history.push('/')
        }).catch(() => {
            setLogoutError(true);
        });
    }

    return(<>
        <Container fluid className='containerStyle'>
            { logoutError ? <AlertGeneral setTrigger={setLogoutError} closeAlone={false} color='danger' description={"Erorre nel logout, riprova piu' tardi "} /> : null}
            { isEmpty(auth) && !isLoaded(auth) ? 
                <LoadingButton />
            :
                <Row style={{marginTop: '2rem'}}>
                    <Col xs={12} md={4} className={classes.borderStyle}>
                        <SideMenu onChangeChoosenTabHandler={onChangeChoosenTabHandler} 
                            choosenTab = {choosenTab} auth={isLoaded(auth) && !isEmpty(auth) ? auth : null }
                            logoutHandler = {logoutHandler}
                        />
                    </Col>
                    <Col style={{marginTop: '1rem'}}>
                        {renderProfileComponent()}
                    </Col>
                </Row>
            }
        </Container>
    
    
    </>)
}


export default ProfilePage;