import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
//--------------- Components ---------------------
import LoadingButton from '../../../Components/Commons/LoadingButton/LoadingButton';
import classes from './SupplierPage.module.css';
import { Link } from 'react-router-dom';
import { getTimetableString } from '../../../models/supplier';
import NewlineText from '../../Commons/NewlineText';

const MainPage = ({ loadingSupplier, fetchedSupplier, renderSupplier, searchedSuppliers, searchedText }) => {
    return (<>
        {loadingSupplier ? <LoadingButton /> :
            fetchedSupplier.length ?
                <Row>
                    {renderSupplier().map(supplier => {
                        return (
                            <Col key={supplier.name} xs={12} md={6} lg={3} style={{ marginTop: 10, padding: 10 }}>
                                <Link to={`/supplier/${supplier.reference.id}`}>
                                    <Card className={classes.cardStyle}>
                                        <Image src={supplier.imageUrl} style={{ width: '100%', borderTopLeftRadius: 15, borderTopRightRadius: 15 }} className={classes.cardImageHeight} />
                                        <div className={"d-flex justify-content-between align-content-center flex-column " + classes.infoHeight} >
                                            <div>
                                                <div className={classes.textName}>{supplier.name}</div>
                                                <div style={{ fontSize: 15 }}>{supplier.area.name}</div>
                                            </div>

                                            <div style={{ fontWeight: 600 }}>
                                                <NewlineText text={getTimetableString(supplier)} />
                                            </div>
                                        </div>
                                    </Card>
                                </Link>
                            </Col>
                        )
                    })}
                </Row>
                :
                <>
                    <span className='text-danger'>Non ci sono supplier</span>
                </>}
        {!searchedSuppliers.length && searchedText ?
            <Row style={{ marginTop: '2rem' }}>
                <Col>
                    <span>La tua ricerca non ha prodotto risultati</span>
                </Col>
            </Row>
            : null}
    </>)
}

export default MainPage;