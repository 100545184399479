import React, { FunctionComponent, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import classes from './ConfirmCartPage.module.css';
import { Form } from 'react-bootstrap';

type ModalVerifyNumberProps = {
    verifyNumberHandler: (code: string) => any;
    errorNumberVerification: boolean;
    nextStepLoading: boolean;
}

const ModalVerifyNumber: FunctionComponent<ModalVerifyNumberProps> = ({ verifyNumberHandler, errorNumberVerification, nextStepLoading }) => {
    const [code, setCode] = useState("");
    return (<>
        <Modal show={true}  >
            <Modal.Header >
                <Row>
                    <Col>
                        <h3 className={classes.modalTitile}>Verifica il tuo numero</h3>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body >
                <Row>
                    <Col>
                        <span>Inserisci il codice che hai ricevuto per messaggio</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Control name={"code"}
                            className='textStyle'
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                        />
                    </Col>
                </Row>
                {errorNumberVerification ?
                    <Row>
                        <Col>
                            <span className='text-danger'>Il codice e' errato</span>
                        </Col>
                    </Row>
                    : null}
                <Row className="mt-2">
                    <Col>
                        <Button onClick={() => verifyNumberHandler(code)} disabled={nextStepLoading}>Verifica</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>

    </>)
}


export default ModalVerifyNumber;