import React, {useState, useEffect} from 'react'
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AiOutlineAlert } from "react-icons/ai";

interface Props {
    color: string,
    closeAlone?: true | false,
    description: string,
    setTrigger?: Function,
}

const AlertStatic:React.FC<Props> = ({color,closeAlone,description,setTrigger}) => {
 
    useEffect( () => {
        if(closeAlone && setTrigger){
            const time = setTimeout(() => setTrigger(false), 3000)
            return() => {
                clearTimeout(time)
            }
        }
    },[])

    return(
        <>
        <Alert style={{zIndex: 99, width: '100%', marginTop: 10}} variant={color}>
            <div style={{display: 'flex'}}>
                <div className='my-auto'>
                    <AiOutlineAlert size={24}/>
                </div>
                <div className='my-auto text-left' style={{marginLeft: 20}}>
                    <span style={{fontWeight: 'bold'}}>{description}</span>
                </div>
            </div>
        </Alert>
        </>
    );
}


export default AlertStatic;