import { createStore, applyMiddleware, Action } from "redux";
import thunk, { ThunkAction } from "redux-thunk";
import { getFirebase } from 'react-redux-firebase';
import { useDispatch } from "react-redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer, { RootState } from "./reducer/rootReducer";

const store = createStore(rootReducer,
  composeWithDevTools(applyMiddleware(thunk.withExtraArgument({ getFirebase }))),
);

export type AppDispatch = typeof store.dispatch;
// eslint-disable-next-line no-undef
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;