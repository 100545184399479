import { forwardRef } from 'react'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

type Option = {
    value: string;
    title: string;
};

interface Props {
    name?: string;
    placeHolder?: string;
    labelName?: string;
    labelStyle?: {};
    error?: string;
    inputStyle?: {};
    offset?: number;
    span?: number;
    disabled?: boolean;
    errorStyle?: {};
    options: Option[],
}

const Select = forwardRef<HTMLSelectElement, Props>(({ name, placeHolder, labelName, labelStyle, error, inputStyle, offset, span, disabled, errorStyle, options }, ref) => {
    return (<>
        <Row>
            <Col >
                <Form.Label >
                    <span className={'formLabelStyle'} style={labelStyle}>{labelName}</span>
                </Form.Label>
            </Col>
        </Row>
        <Row >
            <Col md={{ span: span, offset: offset }}>
                <Form.Select name={name}
                    placeholder={placeHolder}
                    className='textStyle'
                    style={inputStyle}
                    ref={ref}
                    disabled={disabled}
                >
                    {
                        options.map((o) => <option value={o.value} key={o.value}>{o.title}</option>)
                    }
                </Form.Select>
            </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
            <Col>
                {error && (<span className='errorStyle' style={errorStyle}>{error}</span>)}
            </Col>
        </Row>
    </>
    )
})

export default Select;