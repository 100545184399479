import React from 'react';
import ReduxToastr, { ReduxToastrProps } from 'react-redux-toastr'
import ReactDOM from 'react-dom';
import './index.css';
import App from './Containers/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from "react-ga";
//-----------------Redux Import ------------------------------
import { Provider } from 'react-redux';
//---------------Firebase Import----------------------------
import { createFirestoreInstance } from 'redux-firestore';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import firebase from './config/fbConfig'
//-------------------------------------------------------------
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import "react-datetime/css/react-datetime.css";
import 'bootstrap/dist/css/bootstrap.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import store from './store/store';

const stripePromise = loadStripe(process.env.REACT_APP_ENV === "production" ? "pk_live_qwugTzFCQS6jtnuUR0FUOEr400Y7D3tikq" : 'pk_test_bI6Z2I2jFP7Tfjfm0AvIyWV500cS2fKdCO');
ReactGA.initialize('UA-163462032-1');

const rrfConfig = {
	userProfile: 'users',
	enableClaims: true,
	useFirestoreForProfile: true
}

const rrfProps = {
	firebase: firebase,
	config: rrfConfig,
	dispatch: store.dispatch,
	createFirestoreInstance: createFirestoreInstance
}

const toastrProps: ReduxToastrProps = {
	timeOut: 4000,
	newestOnTop: false,
	preventDuplicates: true,
	position: "top-right",
	transitionIn: "fadeIn",
	transitionOut: "fadeOut",
}

ReactDOM.render(
	<Elements stripe={stripePromise}>
		<Provider store={store}>
			<ReactReduxFirebaseProvider {...rrfProps}>
				<BrowserRouter >
					<App />
					<ReduxToastr {...toastrProps} />
				</BrowserRouter>
			</ReactReduxFirebaseProvider>
		</Provider>
	</Elements>,
	document.getElementById('root')
);

reportWebVitals();
