import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import classes from './SupplierDetailPage.module.css';

const ModalCheckAllergy = ({setAllergy, phoneNumber}) => {

    return(<>
        <Modal show={true}  >
            <Modal.Header >
                <Row>
                    <Col>
                        <h3 className={classes.modalTitile}>Allergie</h3>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body >
                <Row>
                    <Col className='text-center'>
                        <span style={{fontWeight: 'bolder', fontSize: 16}}>Allergia o intolleranza alimentare ?</span>
                    </Col>
                </Row>
                <Row style={{marginTop: 10}}>
                    <Col>
                        <span>
                            Se hai un' allergia o un'intolleranza alimentare, o una persona per cui stai effettuando l'ordine ne ha, contatta subito questa attivita'.
                        </span>
                    </Col>
                </Row>
                <Row style={{marginTop: 10}}>
                    <Col className='text-center'>
                        <span style={{fontWeight: 'bold'}}> 
                            {phoneNumber}
                        </span>
                    </Col>
                </Row>
                <Row style={{marginTop: 10}}>
                    <Col className='text-center'>
                        <Button onClick={() => setAllergy(false)} className='buttonGeneralStyle'
                            style={{width: 100}}
                        >
                            Chiudi</Button>
                    </Col>
                </Row>

            </Modal.Body>
        </Modal>
    
    </>)
}


export default ModalCheckAllergy;