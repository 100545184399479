import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
//--------------------------------------
import Features from '../../../Components/PublicPages/Home/Features';
//------------ Other import -------------
import ReactPlayer from 'react-player'
import TaportyVideo from '../../../Assets/Video/taporty-video.mp4'
import classes from './HowItWork.module.css'
import Apple from '../../../Assets/Images/apple_store.png';
import Google from '../../../Assets/Images/google_play.png';
import CfMap from '../../../Assets/Images/CF-map.png';
import Cf from '../../../Assets/Images/CF.png';

const HowItWork = () => {
    return(<>
        <Container fluid >
            <Row>
                <Col>
                    <ReactPlayer url={TaportyVideo} controls={true} 
                    width={'100%'}/>
                </Col>
            </Row>
            <Row style={{marginTop: '1rem'}}><Col>
                <span className={classes.titleFeature}>Ordinare non e' mai stato cosi facile</span>
            </Col></Row>
            <Row>
                <Col>
                    <Features />
                </Col>
            </Row>
            <Row style={{marginTop: '3rem'}}>
                <Col>
                    <p className={classes.titleDownload}>Cosa aspetti ?</p>
                    <p className={classes.descriptionDownload}>Scarica subito la nostra app</p>
                </Col>
            </Row>
            <Row>
                <Col lg={{span: 4, offset: 4}}>
                    <Row>
                        <Col xs={12} md={6} style={{marginTop: 10}}>
                            <a href="https://apps.apple.com/it/app/taporty/id1510551452" target='_blank' >
                                <Image src={Apple} style={{width: '80%'}}/>
                            </a >
                        </Col>
                        <Col className={classes.marginDownload} style={{marginTop: 10}}>
                            <a href="https://play.google.com/store/apps/details?id=it.taporty.customer&hl=en&gl=US" target='_blank' >
                                <Image src={Google} style={{width: '80%'}}/>
                            </a >
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{marginTop: '6rem'}}>
                <Col>
                    <Row style={{backgroundColor: 'red'}}>
                        <Col className={classes.image1MarginTop} style={{paddingLeft: 0}} xs={12} lg={4}>
                            <Image src={Cf} style={{width: '100%'}} className={classes.image1NoDisplay}/>
                        </Col>
                        <Col lg={2}></Col>
                        <Col className={classes.image2MarginTop} xs={12} lg={4}>
                            <Image src={CfMap} style={{width: '100%'}}/>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </Container>
    </>)
}


export default HowItWork;