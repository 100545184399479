import firebase from 'firebase/app'
import OrderProduct from './order_product';
import OrderStates from './order_states';

type Order = {
  products: OrderProduct[];
  productCount: number;
  cartAmount: number;
  deliveryAmount: number;
  points?: number;
  distance: number | null;
  notes: string;

  useOwnDrivers: boolean | undefined;
  supplierPercentage: number;
  driverAmount: number | null;

  state: string;
  hasReview?: boolean;

  // Timestamps
  creationTimestamp: firebase.firestore.Timestamp;
  postponeTimestamp?: firebase.firestore.Timestamp;
  acceptanceTimestamp?: firebase.firestore.Timestamp;
  shiftStartTime: firebase.firestore.Timestamp;
  preferredDeliveryTimestamp: firebase.firestore.Timestamp;
  modificationTimestamp?: firebase.firestore.Timestamp;
  cancellationTimestamp?: firebase.firestore.Timestamp;
  readyTimestamp?: firebase.firestore.Timestamp;
  pickupTimestamp?: firebase.firestore.Timestamp;
  deliveryTimestamp?: firebase.firestore.Timestamp;
  refusalTimestamp?: firebase.firestore.Timestamp;
  archiviationTimestamp?: firebase.firestore.Timestamp;

  // Customer info
  customerId: string;
  customerName: string;
  customerCoordinates: firebase.firestore.GeoPoint;
  hasCustomerCoordinates?: boolean;
  customerAddress: string;
  customerStreetNumber: string;
  customerPhoneNumber: string;
  customerImageUrl: string | null;
  paymentIntentId: string | null;
  useCashPayment?: boolean;
  cashAmount: number | null;

  // Supplier info
  supplierId: string;
  supplierName: string;
  supplierCoordinates: firebase.firestore.GeoPoint;
  supplierAddress: string;
  supplierPhoneNumber: string;
  supplierImageUrl: string;

  // Driver info
  driverId: string | null;
  driverName: string | null;
  driverPhoneNumber: string | null;
  driverImageUrl: string | null;

  // Sconto
  discountCodeId: string | null;
  discountCode: string | null;
  discountAmount: number | null;
  discountPercentage: number | null;

  areaId: string;

  driverSeen?: boolean;

  postponeRequested?: boolean;
  isPreponed?: boolean;

  visualized?: boolean;
  stateChangedByAdmin?: boolean;

  balanceUpdated?: boolean;
  reference?: firebase.firestore.DocumentReference;

  supplierResponsibleForCash: boolean | undefined;
}

export const orderStateToString = ({ state, postponeRequested, isPreponed }: { state: OrderStates, postponeRequested?: boolean, isPreponed?: boolean }) => {
  if (state === 'PICKED_UP') {
    return 'In consegna'
  }

  if (state === 'REFUSED') {
    return 'Ordine Rifiutato'
  }

  if (state === 'NEW') {
    if (postponeRequested === true && isPreponed !== true) return "Richiesta di posticipo";
    if (postponeRequested === true && isPreponed === true) return "Richiesta di anticipo";
    return "Nuovo ordine";
  }

  if (state === 'ACCEPTED') {
    return 'In preparazione'
  }
  if (state === 'DELIVERED') {
    return 'Consegnato'
  }
  if (state === 'CANCELLED') {
    return 'Ordine annullato'
  }
  if (state === 'READY') {
    return 'Pronto'
  }

  return "";

}

export default Order;