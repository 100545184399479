import React from 'react';
//-------------- Bootstrap ------------------
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
//-------- Other import -------------------
import { Link } from 'react-router-dom';
import ReactGA from "react-ga";
import Image403 from '../../Assets/Images/403.png';
import { Helmet } from "react-helmet";
import { FaHome } from 'react-icons/fa';
//--------------Style--------------------------------
import classes from './PageError.module.css';

const trackPage = (page: string) => {
    ReactGA.set({ page });
    ReactGA.pageview(page);
};

const Page403 = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>403</title>
                <meta name="description" content='Non Permesso' />
                <meta name="robots" content="noindex" />
            </Helmet>
            {trackPage('403')}
            <Container>
                <Row style={{ marginTop: '2rem' }}>
                    <Col className='text-left'>
                        <FaHome size={22} />
                        <Link to='/' style={{ paddingLeft: 10 }}>
                            <span className='colorRed'>Home</span>
                        </Link>
                    </Col>
                </Row>
                <Row >
                    <Col xs={{ span: 12 }}>
                        <h1 className='colorRed'>403 e' proibito per te entrare su questa pagina</h1>
                    </Col>
                </Row>
                <Row>
                    <Col lg={{ span: 6, offset: 3 }}>
                        <span >Non puoi utilizzare questa pagina, puoi tornare alla pagina home seguendo il link in alto a sinistra, o cambiare url di destinazione, grazie</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Image
                            className={classes.Image}
                            src={Image403}
                            alt='403-error-image-frutta'
                        />
                    </Col>
                </Row>
            </Container>
        </>

    )
}

export default Page403
