import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import classes from './SupplierDetailPage.module.css';
import {useHistory} from 'react-router-dom';

const ModalRestaurantNotInArea = () => {

    const history = useHistory();

    return(<>
        <Modal show={true}  >
            <Modal.Header >
                <Row>
                    <Col>
                        <h3 className={classes.modalTitile}>Ristorante fuori area</h3>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body >
                <Row>
                    <Col>
                        <span>
                            Il ristorante non si trova nella area che hai scelto, torna alla home page e cambia indirizzo
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button onClick={() => history.push('/')}>Torna alla home</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>   
    </>)
}


export default ModalRestaurantNotInArea;