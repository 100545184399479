import React, { FunctionComponent } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { FiAlertCircle } from 'react-icons/fi';
import Supplier from '../../../models/supplier';
import Settings from '../../../models/settings';
import Product from '../../../models/product';
import Cart, { CartProduct, getCartAmount, getCartProductAmount } from '../../../models/cart';
import DiscountCode from '../../../models/discount_code';
import { getAmountDetails } from '../../../functions/amount_helpers';
import Area from '../../../models/area';

type CartPageProps = {
    supplier: Supplier,
    settings: Settings,
    area: Area,
    products: Product[],
    currentCart: Cart,
    discount?: DiscountCode | null,
    isSummary: boolean,
    isEditOrder: boolean,
    onClickAddSameItemInCart?: (id: number) => any,
    onClickDeleteSameItemInCart?: (id: number) => any,
    onClickEditItemHandler?: (id: number) => any,
    onClickOrderNowHandler?: () => any
}

const CartPage: FunctionComponent<CartPageProps> = ({ supplier, settings, area, products, currentCart,
    onClickAddSameItemInCart, onClickDeleteSameItemInCart, onClickEditItemHandler,
    onClickOrderNowHandler, isSummary, isEditOrder, discount }) => {

    const renderListPreferencesIfExist = (productInCart: CartProduct) => {
        let listPreference = null;
        if (productInCart.preferences && productInCart.preferences.length > 0) {
            listPreference = productInCart.preferences.map(preference => {
                return (
                    `${preference.name} `
                )
            })
        }

        return listPreference
    }

    const {
        cartAmount,
        deliveryAmount,
        discountAmount,
        totalAmount
    } = getAmountDetails(getCartAmount(currentCart, products), discount, settings, area, supplier, null, isSummary);

    return (<>
        { /* !isReview ?
            supplier.minOrderAmount ? 
                <Row>
                    <Col><span>MINIMO ORDINE: {supplier.minOrderAmount}</span></Col>
                </Row>
            : null
        : null }
        {!isReview ?
            supplier.freeDeliveryThreshold ?
                <Row>
                    <Col><span>Spedizione gratuia sopra i {supplier.freeDeliveryThreshold}</span></Col>
                </Row>
            : settings.freeDeliveryThreshold ? 
                <Row>
                    <Col><span>Spedizione gratuia sopra i {settings.freeDeliveryThreshold}</span></Col>
                </Row>
            : null 
        : null */ }
        <Row>
            <Col>
                {currentCart.products && currentCart.products.length ?
                    currentCart.products.map(productInCart => {
                        let product = products.find(product => product.id === productInCart.id);
                        if (product === undefined)
                            return <></>;
                        return (<>
                            <Row style={{ marginTop: 10 }}>
                                <Col xs={10} className='text-left'>
                                    {!isSummary ?
                                        <Link to="" onClick={() => onClickEditItemHandler!(productInCart.uniqId)}>
                                            <span style={{ fontWeight: 'bolder' }}>{productInCart.quantity} x </span>
                                            <span style={{ fontWeight: 'bolder' }}>{product.name}</span>
                                        </Link>
                                        :
                                        <>
                                            <span style={{ fontWeight: 'bolder' }}>{productInCart.quantity} x </span>
                                            <span style={{ fontWeight: 'bolder' }}>{product.name}</span>
                                        </>
                                    }
                                </Col>
                                <Col xs={2}>
                                    <span>€ {getCartProductAmount(productInCart, product)} </span>
                                </Col>
                            </Row>
                            <Row>
                                {!isSummary ?
                                    <Col xs={1}>
                                        <Button className='buttonGeneralStyle' style={{ width: 30, padding: 1 }} onClick={() => onClickDeleteSameItemInCart!(productInCart.uniqId)}>-</Button>
                                    </Col>
                                    : null}
                                <Col className='text-left'>
                                    <span >{renderListPreferencesIfExist(productInCart)}</span>
                                </Col>
                                {!isSummary ?
                                    <Col xs={2}>
                                        <Button className='buttonGeneralStyle' style={{ width: 30, padding: 1 }} onClick={() => onClickAddSameItemInCart!(productInCart.uniqId)}>+</Button>
                                    </Col>
                                    : null}
                            </Row>
                        </>)
                    }) : null}
            </Col>
        </Row>
        <Row style={{ marginTop: '1rem' }}>
            <Col className='text-left'>
                <span>Prezzo prodotti: {cartAmount.toFixed(2)}€</span>
            </Col>
        </Row>
        {
            discount !== null && discount !== undefined &&
            <Row>
                <Col className='text-left'>
                    <span>Sconto: -{discountAmount.toFixed(2)}€</span>
                </Col>
            </Row>
        }
        <Row>
            <Col className='text-left'>
                <span>Prezzo consegna: {deliveryAmount.toFixed(2)}€</span>
            </Col>
        </Row>
        <Row>
            <Col className='text-left'>
                <FiAlertCircle /><span> Le spese di consegna sono calcolate sulla base della posizione inserita.</span>
            </Col>
        </Row>
        <Row>
            <Col className='text-left'>
                <span style={{ fontWeight: 'bolder', color: 'black' }}>Totale: {totalAmount.toFixed(2)}€</span>
            </Col>
        </Row>
        {!isSummary ?
            <Row style={{ marginTop: 20 }}>
                <Col>
                    <Button className='buttonGeneralStyle' disabled={cartAmount <= (supplier.minOrderAmount ?? 0)} onClick={() => onClickOrderNowHandler!()}>
                        {isEditOrder ? 'Modifica Ordine' : 'Ordina Adesso'}
                    </Button>
                </Col>
            </Row>
            : null}
    </>);
}


export default CartPage;